<template>
  <div>
    <base-page-title :titleText="pageTitle" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageTitle: "page.support.title"
    };
  }
};
</script>

<style scoped></style>
